<template>
  <div class="blue">
    <!-- <form @submit.prevent="submitForm">
      <input type="text" name="name" v-model="name"/>
      <input type="email" name="email"  v-model="email"/>
      <textarea name="message" v-model="message"></textarea>
      <button type="submit">Send Message</button>
    </form> -->
      <transition name="fade" mode="out-in">
        <div class="thanksBox" v-show="result == true">
          <p class="thanksText">Thanks for contacting us, we will get back to you shortly...</p>
        </div>
      </transition>
      <template v-if="result != true">
        <b-form @submit.prevent="submitForm">
          <input type="hidden" name="subject" value="Golf Park Enquiry">
          <b-row class="rowPadMob">
              <b-col md="23" sm="12" class="RightPad">
                  <b-form-input
                  type="text"
                  v-model="name"
                  placeholder="Your Name"
                  />
              </b-col>
              <b-col md="12" sm="12" class="leftPad">
                  <b-form-input
                  type="text"
                  v-model="email"
                  placeholder="Your Email Address"
                  />
              </b-col>
              <b-col md="12" sm="12" class="RightPad">
                  <b-form-input
                  type="text"
                  v-model="phone"
                  placeholder="Your Phone Number"
                  />
              </b-col>
              <b-col md="12" sm="12" class="leftPad">
                  <!-- <b-form-select v-model="selected" :options="options" ></b-form-select> -->
                  <div class="experienceSection">
                    <h1 class="starArea">How would you rate your overall experience?</h1>
                    <div class="">
                        <span :v-model="Rating" @click="gfg(1)"
                              class="star">★
                        </span>
                        <span :v-model="Rating" @click="gfg(2)"
                              class="star">★
                        </span>
                        <span :v-model="Rating" @click="gfg(3)"
                              class="star">★
                        </span>
                        <span :v-model="Rating" @click="gfg(4)"
                              class="star">★
                        </span>
                        <span :v-model="Rating" @click="gfg(5)"
                              class="star">★
                        </span>
                        <h3 id="output">
                              Rating is: 0/5
                        </h3>
                    </div>
                  </div>
              </b-col>
              <b-col md="12" sm="12">
                  <b-form-textarea
                      v-model="message"
                      rows="11"
                      cols="1"
                      class="form-control enquiryForm"
                      placeholder="Your Feedback"
                  >
                  </b-form-textarea>
              </b-col>
              <button
                data-text="submit"
                type="submit"
                class="HomepageBtn"
              >
                Submit
              </button>
          </b-row>
        </b-form>        
      </template>

  </div>
</template>

<style scoped>
#output {
  padding-top: 10px;
  font-size: 21px;
  color: #fff;
  font-family: "noto-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.star {
  font-size: 3vh;
  cursor: pointer;
}
 
.one {
  color: rgb(255, 0, 0);
}
 
.two {
  color: rgb(255, 106, 0);
}
 
.three {
  color: rgb(251, 255, 120);
}
 
.four {
  color: rgb(255, 255, 0);
}
 
.five {
  color: rgb(24, 159, 14);
}
::v-deep textarea.form-control.enquiryForm.form-control {
  padding-top: 12px;
}
.row.rowPadMob {
  width: 62%;
  margin: auto;
}
.experienceSection {
  padding-top: 10px;
  padding-bottom: 20px;
}
.starArea {
  font-size: 21px;
  color: #fff;
  font-family: "noto-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.RightPad.col-md-6.col-6 {
    padding-right: 7px;
}
.leftPad.col-md-6.col-6 {
    padding-left: 7px;
}
.enquiryForm {
    margin-bottom: 70px!important;
    display: block;
}
select.custom-select {
    height: 51.5px;
    border-radius: 0;
    font-size:18px;
    border-radius: 0px;
    margin-bottom: 15px;
    color: #4E4E4E;
    font-style: italic;
}
button.HomepageBtn {
    background-color: #d80e1f;
    border-color: #d80e1f!important;
    border-radius: 50px;
    width: 163px;
    height: 31px;
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 252px;
    height: 54px;
    margin-top: 65px;
    margin: auto;
    text-align: center;
    display: block;
    font-family: "noto-sans", sans-serif;
}
button.HomepageBtn:hover {
    background-color: #707070;
    border-color: #707070!important;
    color: #fff;
    border: none;
}
::v-deep .btn-danger {
    color: #fff;
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
textarea.form-control {
    height: 130px;
}
.thanksBox {
    text-align: center;
    border: 5px solid #fff;
    margin-top: 0em;
}
.thanksText {
    font-size: 21px;
    color: #fff;
    /* font-weight: bold; */
    margin-top: 1rem;
}
.blue {
    background-size: cover;
    padding-bottom: 5%;
    /* padding-top: 15%; */
    text-align: left;
}

::v-deep .form-control{
    font-size:18px;
    border-radius: 0px;
    margin-bottom: 15px;
    padding: 1.575rem 0.75rem;
    color: #4E4E4E;
    font-style: italic;
}
.form-control::placeholder {
    color: #4E4E4E;
    opacity: 1;
}

/* Mobile Changes */

@media only screen and (max-width: 480px) {
    .mobPad{
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 768px) {
  .enquiryForm {
      margin-bottom: 30px !important;
      display: block;
  }
  .rowPadMob {
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>

<script>
  // matt.edmeads@ocs-software.com key
  // const WEB3FORMS_ACCESS_KEY = "7c7b6d13-39f5-42e2-9047-8155a7f86557";
  // support@ocs-software.com key
  const WEB3FORMS_ACCESS_KEY = "35df1117-0cd8-457d-a9b2-17f5eeb54cd6";

  export default {
    data() {
      return {
        website: 'SimPark Wesbite Review',
        name: "",
        email: "",
        phone: "",
        message: "",
        Rating: '',
        options: [
          { value: null, text: 'Please Select from the dropdown list' },
          { value: 'Book a tour', text: 'Book a tour' },
          { value: 'Private and discreet booking', text: 'Private and discreet booking' },
          { value: 'Corporate membership', text: 'Corporate membership' },
          { value: 'Pay and play', text: 'Pay and play' },
          { value: 'Group membership', text: 'Group membership' },
          { value: 'Golf lessons', text: 'Golf lessons' },
          { value: 'General enquiry', text: 'General enquiry' },
        ],
        // selected: null,
        result: '',
        text: '',
        outPut: ''
      };
    },
    methods: {
      async submitForm() {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: WEB3FORMS_ACCESS_KEY,
            website: this.website,
            name: this.name,
            email: this.email,
            phone: this.phone,
            Rating: this.outPut,
            message: this.message,
            selected: this.selected
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(result);
          this.result = result.success;
          this.text = 'Thanks for contacting us, we will get back to you shortly...'
        }
      },
      gfg: function(n) {
        this.remove();
        let stars = 
            document.getElementsByClassName("star");
        let output = 
            document.getElementById("output");
        let cls = ''
        this.newclass = cls
        for (let i = 0; i < n; i++) {
            if ( n == 1) cls = "one";
            else if ( n == 2) cls = "two";
            else if ( n == 3) cls = "three";
            else if ( n == 4) cls = "four";
            else if ( n == 5) cls = "five";
            stars[i].className = "star " + cls;
        }
        // for (let i = 0; i < n; i++) {
        //     if ('Poor' + n == 1) cls = "one";
        //     else if ('Ok' + n == 2) cls = "two";
        //     else if ('Good' + n == 3) cls = "three";
        //     else if ('Great' + n == 4) cls = "four";
        //     else if ('Excellent' + n == 5) cls = "five";
        //     stars[i].className = "star " + cls;
        // }
        if (n == 1) { 
          output.innerText = 'Poor ' + '1' + "/5";
          this.outPut = output.innerText;
        } else if (n == 2) {
          output.innerText ='Below Average ' + '2' + "/5";
          this.outPut = output.innerText;
        } else if (n == 3) {
          output.innerText = 'Average ' + '3' + "/5";
          this.outPut = output.innerText;
        } else if (n == 4) {
          output.innerText = 'Above Average ' + '4' + "/5";
          this.outPut = output.innerText;
        } else if (n == 5) {
          output.innerText = 'Excellent ' + '5' + "/5";
          this.outPut = output.innerText;
        } else {
          output.innerText = n + "/5";
          this.outPut = output.innerText;
        }
      }, 
      remove :function() {
      let stars = 
            document.getElementsByClassName("star");
        let i = 0;
        while (i < 5) {
            stars[i].className = "star";
            i++;
        }
    }
    },
  };
</script>
